.download__container {
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.download__header {
    font-size: 1.25rem;
    font-weight: bold;
    padding: 1rem;
    text-align: center;
}

.download__row-container {
    padding: 2rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.download__row-item {
    padding: 0 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 300px;


}
.download__row-item-text {
    padding: 0 1rem;
    text-align: center;

}
.download-row-logo-ios {
    width: 100px;
}
.download-row-logo-android {
    width: 175px;
}

.download__text {
   padding: 1rem 0;
}

.download__text-small {
    font-size: 0.75rem;
}


@media screen and (max-width: 700px) {

    .download__row-container {
        flex-direction: column;

    }
  }