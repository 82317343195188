.home-admin__main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.home-admin__container {

    width: 400px;
}

.home-admin__coupon-container {
    border-radius: 0.3rem;
    border-style: solid;
    border-width: 2px;
    border-color: var(--ion-color-primary);
    overflow-y: scroll;
    height: 100px;
}
.home-admin__coupon-item {
    padding: 0.2rem 0;
}

@media screen and (max-width: 420px) {
    .home-admin__container {

        width: 340px;
    }
  }

  @media screen and (max-width: 360px) {
    .home-admin__container {

        width: 290px;
    }
  }