.faq__main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.faq__content {
    font-size: 1rem;
}

.faq__footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.faq__footer-text {
    padding: 2rem 0 0.25rem; 
    font-size: 1rem;
}