.contact-main-container {
  width: 100%;
  height: 100%;
  background-color: var(--ion-color-primary);
  align-items: stretch;
  padding: 4rem;
}

.contact-simple-text {
  font-size: 1.25rem;
  color: #fff;
  padding: 1rem 0;
  text-shadow: 0px 3px 6px #00000059;
}

.contact-footer-container {
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contact-footer-icnon-container {
  color: #fff;
  border-width: 0.15rem;
  border-color: #fff;
  border-radius: 0.5rem;
  border-style: solid;
  padding: 0.3rem 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-footer-text-container {
  color: #fff;
  padding-left: 1rem;
}

.contact-footer-mail-btn {
  display: block;
}

.contact-footer-call-btn{
  display: none;
}
.contact-footer-call-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media screen and (max-width: 720px) {
  .contact-footer-mail-btn {

      display: block;
  }
  .contact-footer-call-btn{
    display: block;
  }
  .contact-footer-call-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
