.choose-subscription-plan__mobile-container {
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 2rem 1rem;
}

.choose-subscription-plan__desktop-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 2rem 1rem;
}

.choose-subscription-plan__button-container {
    width: 50%;
    margin: 1rem 0;
}
.choose-subscription-plan__button-call {

    margin: 1rem 0;
}
.choose-subscription-plan__header {
    font-size: 1.5rem;
    font-weight: 200;
    margin-top: 0.75rem;
    text-shadow: 0px 3px 6px #00000059;
}

.choose-subscription-plan__call-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.choose-subscription-plan__call-text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 0.75rem;
}

.choose-subscription-plan__call-button-icon{
    height: 24px;
  width: 24px;

}

.choose-subscription-plan__call-text-small {
    font-size: 0.75rem;
    font-weight: 100;
    text-shadow: 0px 3px 6px #00000059;
    padding-bottom: 0.25rem;
}

.choose-subscription-plan__call-text-big {
    font-size: 1.25rem;
    font-weight: bolder;
    text-shadow: 0px 3px 6px #00000059;
}


@media screen and (max-width: 650px) {
    .choose-subscription-plan__mobile-container {
        display: flex;

    }
    .choose-subscription-plan__desktop-container {
        display: none;
    }
  }