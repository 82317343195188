.base-form {
  width: 100%;
  height: 100%;
  /* background-color: green; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.base-form__fields {
  /* background-color: red; */
  width: 100%;
}

.base-form__submit-button {
  width: 100%;
  margin-top: 2.5rem;
}
