.main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 100%; */
    min-height: 100vh;
    justify-content: space-between;
    align-items: space-between;
}
.stripe-account-success-container {
    
    padding: 2rem;
}

.stripe-account-success__text-info-container {
    text-align: center;
}
.stripe-account-success__success-text {
    text-align: center;
    color: var(--ion-color-success);
    font-size: 1.25rem;
    font-weight: bolder;
    
}

.stripe-account-success__success-icon {
    text-align: center;
    width: 82px;
    height: 82px;
    margin: 1rem 0;
}

.stripe-account-success__text-info {
    font-size: 1rem;
    text-shadow: 0px 3px 6px #00000059;
    margin : 0.3rem 0;
}
.stripe-account-success__button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 2rem;
}

.stripe-account-success__button-icon {
    width: 24px;
    height: 24px;
    padding-left: 0.5rem;
}

.stripe-account-success__button-main {
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
}