.base-text-input {
  position: relative;
}
.base-text-input__input {
  -webkit-appearance: none;
  width: 100%;
  padding: 0.75rem 0.5rem;
  border-radius: 0.33rem;
  border-style: solid;
  border-color: var(--ion-color-medium);
  transition: border-color 250ms ease;
  outline-color: var(--ion-color-primary);
}
.base-text-input__input:focus {
  border-color: var(--ion-color-primary);
}
.base-text-input__label {
  font-size: 0.75rem;
  padding: 0 0.25rem;
  background-color: var(--ion-background-color);
  position: relative;
  top: 0.45rem;
  left: 0.5rem;
  transition: color 250ms ease;
}

.base-text-input:focus-within > .base-text-input__label {
  color: var(--ion-color-primary);
}
.base-text-input__ion-icon {
  cursor: pointer;
  position: absolute;
  font-size: 1.5rem;
  color: var(--ion-color-medium);
  right: 1rem;
  top: 2rem;
}
.base-text-input__error {
  display: block;
  color: var(--ion-color-danger);
  font-size: 0.75rem;
  position: relative;
  top: 0.25rem;
  left: 0.75rem;
}
.base-text-input__hint-link {
  position: relative;
  top: 0.5rem;
  font-size: 0.825rem;
}
