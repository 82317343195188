
.home-header__main-container {
   transition: height ease 0.9s;
}

.home-header__img-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    /* height: 440px; */
    width: 100%;
    padding: 0 3rem;
}

.home-header__img-container__mobile {
    flex-direction: row;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--ion-color-primary);
    display: none;
}
.home-header__img {
    height: 440px;
    width:auto;
    padding: 0rem 0.75rem;
    z-index: 1;
}

.home-header__img-mobile {
    height: 248px;
    width: 225px;
    z-index: 1;
}

.home-header__text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem 1rem;
}

.home-header__text-simple {
    font-size: 3rem;
    font-weight: bolder;
    color: var(--ion-color-secondary);
    letter-spacing: 2px;
}

.home-header__text-bold {
    font-size: 3rem;
    font-weight: 900;
    color: var(--ion-color-primary);
    letter-spacing: 2px;
}

.home-header__img-printer {
    max-height: 200px;
    max-width: 250px;
    min-height: 150px;
    min-width: 200px;
    position: absolute;
    right: -5%;
}

.home-header__img-tablette {
    position: absolute;
    right: -50px;
    height:100%;
    width: 100%;
    max-height: 200px;
    max-width: 250px;
}

.home-header__features-container {
    border-top: 0.15rem solid var(--ion-color-primary);
    border-bottom: 0.15rem solid var(--ion-color-primary);
    padding: 1rem;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    margin: 0;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.home-header__reveal {
    opacity: 0;
    transform: translateX(-40%);
    
}

.home-header__reveal-visible {
    opacity: 1;
    transform: translateX(0);
    transition: 1.5s cubic-bezier(0.54, .01,0,.99);
}
.home-header__features-text {
    padding : 0.2rem 0.5rem;
    font-weight: bold;
    font-size: 1.3rem;
}

@media screen and (max-width: 1540px){
    .home-header__img {
        height: 360px;
    }
}


@media screen and (max-width: 1320px){
    .home-header__img {
        height: 280px;
    }
}

@media screen and (max-width: 1320px){
    .home-header__img {
        height: 170px;
    }
}

@media screen and (max-width: 850px){
    .home-header__img {
        height: 100px;
    }
}
@media screen and (max-width: 700px) {

    .home-header__features-container {
        margin: 0.5rem 0;
    }
    .home-header__img-container {
        display: none;
    }
    .home-header__img-container__mobile {
        display: flex;
    }
  }

  @media screen and (max-width: 450px) {
    .home-header__img-tablette {
        max-height: 195px;
        max-width: 230px;
        height:100%;
    width: 100%;
    }
    .home-header__img-mobile {
        max-height: 248px;
    }
    .home-header__img-printer {
        max-height: 250px;
        max-width: 200px;
        min-height: 100px;
        min-width: 150px;
        position: absolute;
        right: -5%;
    }
  }