.start-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
}

.start-choices {
  width: 100%;
  max-width: 24rem;
  position: relative;
  top: 6rem;
}

.home-back-container {
  width: 100%;
  max-width: 24rem;
  position: relative;
  top: 8rem;
}

.start-choices__signup-button {
  margin: 1rem 0;
  font-size: 1.25rem;
}
.start-choices__signin-button {
  margin: 1rem 0;
  font-size: 1.25rem;
}
