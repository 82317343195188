.payment-method-container {
    color: var(--ion-color-secondary);
    font-size: 1.3rem;
    margin-bottom: 2rem;
}

.payment-method-container-options {
    display: flex;
    flex-wrap: wrap;

}

.payment-method-item {
    padding: 1rem;
    width: 33.333%;
    word-wrap: break-word;
    box-sizing: border-box;

}

#payment-element {
    padding: 1rem;
    width: 100%;
    height: 100%;
}

.payment-element-form-label {

    font-size: 1rem;
    padding : 0.5rem;
}

.payment-element-form-card {
    border-style: solid;
    border-width: 0.1rem;
    border-radius: 0.3rem;
    border-color: var(--ion-color-secondary);
    
}
.payment-element-form-container {
    flex: 1;
    padding-right: 1rem;
}
.payment-element-form-container-cvc-expiry {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 1rem;
}