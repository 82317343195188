.footer-container {
    width: 100%;
background-color: var(--ion-color-primary);
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 2rem;
}

.footer-container div{
    color: #fff;
    margin: 1rem;
    text-shadow: 0px 3px 6px #00000059;
    font-size: 1rem;
}

.footer-element {
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .footer-container {
        flex-direction: column;
        padding: 1.5rem;
    }
    .footer-container div{

        margin: 0.4rem 0;

    }
  }