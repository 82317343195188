.video-main-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.video-header {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bolder;
    padding: 2rem 0;
    text-shadow: 0px 3px 6px #00000059;
}
.video-asset {
    margin: 2rem 0;
    width: 640px;
    height: 480px;
}
.video-footer {
    text-align: center;
    text-shadow: 0px 3px 6px #00000059;
}

.video-track-container {
    background-color: black;
    margin: 2rem 0;
    height: 200px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    
}

.video-call-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 650px){
    .video-asset {
        width: 512px;
        height: 384px;
    }
  }

@media screen and (max-width: 500px){
    .video-asset {
        width: 384px;
        height: 288px;
    }
  }

@media screen and (max-width: 400px){
    .video-asset {
        width: 320px;
        height: 240px;
    }
  }