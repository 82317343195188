.refresh-stripe-link-main-container {
    padding : 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.refresh-stripe-link-text-container {
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
    text-shadow: 0px 3px 6px #00000059;
    padding: 1rem 0;
}

.refresh-stripe-link-text-small {
    text-align: center;
    font-size: 1rem;
    text-shadow: 0px 3px 6px #00000059;
    padding: 1rem 0;
}