.account__container {
    margin: 2rem;
}

.account__subscription-status-container {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.account__subscription-stripe-status-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0.5rem;
}

.account__basic-text {
    font-weight: 600;
    padding: 0.5rem ;
    text-shadow: 0px 3px 6px #00000059;
}
.account__signout-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.account__subscription-status__no-subscription {
    color: red;
    font-weight: 600;
    text-shadow: 0px 3px 6px #00000059;
    padding-left: 0.5rem;
}

.account__subscription-status__basic-subscription {
    color: #000;
    font-size: 1.25rem;
    font-weight: 700;
    text-shadow: 0px 3px 6px #00000059;
}

.account__subscription-status__premium-subscription {
    color: var(--ion-color-primary);
    font-size: 1.3rem;
    font-weight: 700;
    text-shadow: 0px 3px 6px #00000059;
}

.account__stripe-container {
    padding: 0.5rem 0;
}
.account__subscription-button-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin : 0.75rem 0;
}

.stripe-button {

    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--ion-color-stripe);
}

.account__subscription-status__stripe-updated {
    color: green;
    font-weight: 600;
    text-shadow: 0px 3px 6px #00000059;
    padding-left: 0.5rem;
}

.account__subscription-stripe-logo {
    width: 70px;
    height: 70px;
}