.collapsible-container {
    margin : 2rem 1rem;
    max-width:50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
     
}


.content {
    text-align: left;
    font-size: 0.8rem;
}
    
.content-parent {
    height: 0px;
    overflow: hidden;
    transition: height ease 0.45s;
    text-align: center;
    
}
    
.content-show {
    height: 200px;
}

.collapsible-label-icon {
    padding-right: 0.25rem;
}

.collapsible-label-container {
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
   
}


@media screen and (max-width: 600px) {
    .collapsible-container {
        max-width: 100%;
    }
  }