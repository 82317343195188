.header__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--ion-color-primary);
}

.header__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header__navbar-menu__icon {
  width: 34px;
  height: 34px;
  cursor: pointer;
}

.header__navbar-links_logo {
  margin-right: 2rem;
}

.header__navbar-links_logo img {
  width: 62.56px;
  height: 16.02px;
}

.header__navbar-links_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.header__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header__navbar-links_container div p a,
.header__navbar-sign p a,
.header__navbar-menu_container div div p a {
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
  line-height: 25px;
  margin: 0 0.5rem;
  padding: 0.5rem 0;
  text-shadow: 0px 3px 6px #00000059;
  cursor: pointer;
  text-decoration: none;
  position: relative;
}

.header__navbar-links_container div p a::before,
.header__navbar-sign p a::before,
.header__navbar-menu_container div div p a::before {
  transition: 300ms;
  height: 2px;
  content: "";
  position: absolute;
  background-color: #fff;
  width: 0%;
  bottom: 0px;
  border-radius: 6rem;
}

.header__navbar-links_container div p a:hover::before,
.header__navbar-sign p a:hover::before,
.header__navbar-menu_container div div p a:hover::before {
  width: 100%;
}

.header__navbar-sign button,
.header__navbar-menu_container button {
  padding: 0.5rem 1rem;
  color: #fff;
  background: #ff4820;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
}

.header__navbar-menu {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 1rem;
  /* height: 100%; */
  width: 100%;
}

.header__navbar-menu svg {
  cursor: pointer;
}

.header__navbar-menu_container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--ion-color-primary);
  z-index: 99;
  text-align: end;
  padding: 1rem;
  position: absolute;
  top: 50px;
  right: 0;
  margin-top: 1rem;
  min-width: 210px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
  transition: height ease 0.9s;
}

.header__navbar-menu_container p {
  margin: 1rem 0;
}

.header__navbar-menu_container-links-menu-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.header__navbar-menu_container-links-menu-info div p a {
  font-weight: 300;
  font-size: 0.5rem;
}
.header__navbar-menu_container-links {
  text-align: center;
}

.header__navbar-links_container_container-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
  opacity: 0.75;
}

.header__navbar-links_container_container-links:hover {
  opacity: 1;
}

.header__navbar-links_container_container-links-logo {
  position: relative;
  color: #fff;
  border-width: 0.15rem;
  border-color: #fff;
  border-radius: 0.5rem;
  border-style: solid;
  padding: 0.3rem 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header__navbar-menu_container-links-sign {
  display: none;
}

.header__navbar-menu-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 0;
  cursor: pointer;
}
.header__navbar-menu-title-left {
  font-size: 1.75rem;

  font-weight: bolder;
  text-shadow: 0px 3px 6px #00000059;
  color: var(--ion-color-secondary);
  padding-right: 0.2rem;
}

.header__navbar-menu-title-middle {
  font-size: 1.75rem;

  font-weight: bolder;
  text-shadow: 0px 3px 6px #00000059;
  color: var(--ion-color-primary);
  padding-right: 0.2rem;
}

.header__navbar-menu-title-right {
  font-size: 1.25rem;

  font-weight: bolder;
  text-shadow: 0px 3px 6px #00000059;
  color: var(--ion-color-secondary);
}
/* .header__navbar-menu_container-overlay {
    position: relative;
    
} */

.header__navbar-links_container_subscribe-container-triangle {
  position: absolute;
  top: 0;
  left: 0;
  border-style: solid;
  border-width: 0 10rem 10rem 10rem;
  border-color: transparent transparent white transparent;
}

.header__navbar-links_container_subscribe-button-subscribe {
  background-color: #fff;
  border-radius: 0.65rem;
  height: 100%;
  width: 100%;
}

.header__navbar-links_container_subscribe-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0.1rem;
  /* width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 50px 50px 50px;
    border-color: transparent transparent gold transparent; */
}

.header__navbar-links_container_subscribe-container-subcontainer {
  cursor: pointer;
  background-color: #fff;
  border-radius: 0.2rem;
  padding: 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 50px 50px 50px;
    border-color: transparent transparent gold transparent; */
}

.header__navbar-links_container_subscribe-container-button {
  border-style: solid;
  border-radius: 0.2rem;
  border-color: var(--ion-color-primary);
  background-color: #fff;
  padding: 0.2rem 0.5rem;

  font-weight: bold;
  font-size: 1.3rem;
  color: var(--ion-color-primary);
}
@media screen and (max-width: 1260px) {
  .header__navbar-links_container {
    display: none;
  }
  .header__navbar-links {
    display: none;
  }
  .header__navbar-menu {
    display: flex;
  }
  .header__navbar {
    background-color: #fff;
  }
}

/* @media screen and (max-width : 700px) {
    .header__navbar {
        padding: 2rem 4rem;
    }
   
} */

@media screen and (max-width: 550px) {
  /* .header__navbar {
        padding: 2rem 4rem;
    } */
  .header__navbar-sign {
    display: none;
  }
  .header__navbar-menu_container {
    top: 50px;
  }
  .header__navbar-menu_container-links-sign {
    display: block;
  }
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  100% {
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: translate(0%, -100%);
    transform: translate(0%, -100%);
  }
  100% {
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
  }
}
