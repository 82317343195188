.base-button {
  margin: 0.5rem 0;
  overflow: visible;
  background-color: #fff;
  transition: 0.25s;
  border-radius: 12px;

}
.base-button--big {
  font-size: 1.25rem;
}
.base-button--tight {
  font-size: 1rem;
  margin: 0;
}

.base-button__overlay {
  position: absolute;
  right:-20%;
  bottom:-65%;
  pointer-events: none
}

.base-button__container {
  position: relative;
  overflow: visible;

}

