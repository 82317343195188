.subscription-info-layout__mobile-container {
  display: none;
  border-style: solid;
  border-width: 0.2rem;
  border-color: var(--ion-color-primary);
  border-radius: 0.35rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  width: 400px;
  margin: 0.65rem 0;
}

.subscription-info-layout__header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 3px 6px #0000005c;
  padding: 0.75rem;
}
.subscription-info-layout__header-right-overlay {
  position: absolute;
  right: -36px;
  bottom: -30px;
  width: 70px;
  height: 70px;
  pointer-events: none;
}
.subscription-info-layout__header-text-price-underline {
  text-decoration: line-through;
  text-decoration-thickness: 1.5px;
  text-decoration-color: var(--ion-color-secondary);
  font-size: 1.5rem;
  font-weight: bolder;
  color: var(--ion-color-primary);
}

.subscription-info-layout__header-right {
  position: relative;
}
.subscription-info-layout__header-coupon {
  padding-top: 0.3rem;
  font-weight: 200;
}
.subscription-info-layout__header-left {
  font-size: 1.25rem;
  text-shadow: 0px 3px 6px #7e7e7e59;
  text-align: center;
}

.subscription-info-layout__header-text-normal {
  font-size: 1.5rem;
  font-weight: bolder;
  color: var(--ion-color-secondary);
}

.subscription-info-layout__header-text-price {
  font-size: 1.5rem;
  font-weight: bolder;
  color: var(--ion-color-primary);
}
.subscription-info-layout__header-icon {
  height: 38px;
  width: 38px;
  margin-left: 1rem;
  cursor: pointer;
}

.subscription-info-layout__feature-icon {
  height: 38px;
  width: 38px;
  margin-left: 1rem;
  width: 15%;
}

.subscription-info-layout__feature-text {
  width: 85%;
}

.subscription-info-layout__desktop-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.subscription-info-layout__desktop-table-container {
  border-collapse: separate !important;
  /* border-collapse: separate !important;
  border-style: solid;
  border-width: 0.2rem;
  border-color: var(--ion-color-primary);
  border-radius: 0.4rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15); */
  margin: 1rem;
  width: 70%;
}
.subscription-info-layout__desktop-table-head-row-container {
  width: 100%;
}

.subscription-info-layout__desktop-table-head-item-left {
  width: 20%;
  padding: 1rem 2rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
}

.subscription-info-layout__desktop-table-head-item-basic {
  width: 26%;
  padding: 1rem 2rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  border-top: 0.2rem solid var(--ion-color-primary);
  border-left: 0.2rem solid var(--ion-color-primary);
  border-right: 0.2rem solid var(--ion-color-primary);
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}

.subscription-info-layout__desktop-table-head-item-premium {
  width: 26%;
  padding: 1rem 2rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  border-top: 0.2rem solid var(--ion-color-primary);
  border-right: 0.2rem solid var(--ion-color-primary);
  border-top-right-radius: 0.4rem;
}
.subscription-info-layout__desktop-table-head-item-premium-middle {
  width: 26%;
  padding: 1rem 2rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  border-top: 0.2rem solid var(--ion-color-primary);
}
.subscription-info-layout__desktop-table-row-item {
  text-align: left;
  padding: 1rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
}

.subscription-info-layout__desktop-table-row-item-logo-basic {
  text-align: center;
  padding: 1rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  border-left: 0.2rem solid var(--ion-color-primary);
  border-right: 0.2rem solid var(--ion-color-primary);
}
.subscription-info-layout__desktop-table-row-item-logo-premium {
  text-align: center;
  padding: 1rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  border-right: 0.2rem solid var(--ion-color-primary);
}
.subscription-info-layout__desktop-table-row-item-logo-premium-middle {
  text-align: center;
  padding: 1rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
}

.subscription-info-layout__desktop-table-row-item-logo-basic__end {
  text-align: center;
  padding: 1rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  border-left: 0.2rem solid var(--ion-color-primary);
  border-bottom: 0.2rem solid var(--ion-color-primary);
  border-bottom-left-radius: 0.4rem;
}
.subscription-info-layout__desktop-table-row-item-logo-premium__end {
  text-align: center;
  padding: 1rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  border-right: 0.2rem solid var(--ion-color-primary);
  border-left: 0.2rem solid var(--ion-color-primary);
  border-bottom: 0.2rem solid var(--ion-color-primary);
  border-bottom-right-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
}

.subscription-info-layout__desktop-table-row-item-logo-premium__end--middle {
  text-align: center;
  padding: 1rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  border-bottom: 0.2rem solid var(--ion-color-primary);
}

.subscription-info-layout__desktop-table-row-item-button {
  text-align: center;
  padding: 1rem;
}
@media screen and (max-width: 650px) {
  .subscription-info-layout__mobile-container {
    display: block;
  }
  .subscription-info-layout__desktop-table-container {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .subscription-info-layout__mobile-container {
    width: 300px;
  }
}

@media screen and (max-width: 320px) {
  .subscription-info-layout__mobile-container {
    width: 250px;
  }
}
