.home-main-content__main-container {

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.home-main-content__row-container-reverse,
.home-main-content__row-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 3% 8%;
  margin: 0 1rem;
  min-height: 350px;
}

.home-main-content__list-container {
  text-align: left;
}

.home-main-content__column-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  margin: 0 1rem;
  text-align: center;
}

.home-main-content__text-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0rem 0.75rem;
  /* align-items: center; */
}

.home-main-content__button-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-main-content__text-bold {
  font-size: 2rem;
  font-weight: bolder;
  text-shadow: 0px 3px 6px #00000059;
  padding: 1rem 0;
  text-align: center;
}

.home-main-content__text-bold-special {
  font-size: 2rem;
  font-weight: bolder;
  text-shadow: 0px 3px 6px #00000059;
  padding: 2rem 0;
}
.home-main-content__text-simple {
  padding: 0.5rem 0;
  text-align: justify;
}

.home-main-content__text-simple-center {
  padding: 0.5rem 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.home-main-content__img-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.75rem;
}
.home-main-content__img-container-row {
  position: relative;
  padding: 1rem;
  margin: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-direction: row;
}
.home-main-content__img-overlay-container {
  position: relative;
}
.home-main-content__img-medium {
  max-height: 300px;
  max-width: 390px;
  padding: 0 0.25rem;
}

.home-main-content__img-big {
  max-height: 450px;
  max-width: 650px;
  padding: 0 0.25rem;
}
.home-main-content__img-medium-close {
  height: 200px;
  padding: 0;
}

.home-main-content__img-small-close {
  height: 150px;
  padding: 0;
}

.home-main-content__img-absolute {
  max-height:350px ;
  max-width: 180px;
}

.home-main-content__img-small--printer {
  max-height: 250px;
  max-width: 250px;
  padding: 0 0.25rem;
}
.home-main-content__img-small {
  max-height: 250px;
  max-width: 250px;
  padding: 0 0.25rem;
}

.home-main-content__img-mobile-phone {
  height: 100%;
  width: 50%;
  padding: 0 0.25rem;
}
.home-main-content__text-bold-color {
  display: inline-block;
  color: var(--ion-color-primary);
}
/* .home-main-content__call-button-container {
    width: min-content;
    padding: 0 2rem;
} */

.home-main-content__img-overlay {
  position: absolute;
  top: -45px;
  right: -45px;
  width: 125px;
  height: 125px;
  background-color: var(--ion-color-primary);
  border-radius: 50%;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fff;
  font-size: 0.9rem;
  font-weight: bold;
}

.home-main-content__reveal [class*="home-main-content__reveal-"] {
  opacity: 0;
  transform: translateX(-40%);
  
}

.home-main-content__reveal-visible [class*="home-main-content__reveal-"] {
  opacity: 1;
  transform: translateX(0);
  transition: 1.5s cubic-bezier(0.54, .01,0,.99);
}

.home-main-content__reveal-visible .home-main-content__reveal-1 {
  transition-delay: .1s;
}
.home-main-content__reveal-visible .home-main-content__reveal-2 {
  transition-delay: .25s;
}

@media screen and (max-width: 950px) {

  .home-main-content__row-container {
    flex-direction: column;
    margin: 2rem 0;
    padding: 3% 5%;
  }
  .home-main-content__row-container-reverse {
    flex-direction: column-reverse;
    margin: 2% 0;
    padding: 3% 5%;

  }
  .home-main-content__img-small--printer {
    display: none;
  }
  .home-main-content__img-container {
    width: 100%;
    padding: 1rem;
  }
  .home-main-content__text-container {
    text-align: center;
    width: 100%;
    padding: 1rem;
  }
  .home-main-content__img-overlay {
    top: -40px;
    right: -40px;
    width: 100px;
    height: 100px;
    font-size: 0.8rem;

  }
  .home-main-content__text-bold-special {
    font-size: 1.75rem;
    padding: 1rem 0;
  }
  .home-main-content__column-container {
  
    padding: 0.9rem;

  }
}
