.coupon-modal__container {
    margin: 2rem;
    height: 100%;
}

.coupon-modal__main-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
}
.coupon-modal__main-text {
    font-size: 1.25rem;
}
.coupon-modal__button-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    
}

.coupon-modal__button {

    width: 45%;
    
}