.subscribe-form_container {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  margin: 2rem 1rem;
}
.subscribe-form_main-container {
  display: flex;
  flex-direction: column;
  max-width: 45%;
  margin: 0 2rem;
}
.subscribe-form_order-resume-container  {
  border-style: solid;
  border-width: 0.2rem;
  border-color: var(--ion-color-primary);
  border-radius: 1%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  
  
}
.subscribe-form_order__button-coupon {
  margin: 1rem 0;
}
.subscribe-form_order-resume-header {
  font-size: 1.5rem;
      text-align: center;
      font-weight: bolder;
      text-shadow: 0px 3px 6px #00000059;
      padding: 1rem;
}

.subscribe-form_order-row {
  box-shadow: 0px 3px 6px #0000005c;
  padding: 1rem;
}

.subscribe-form_order-fees-and-commitment {
  box-shadow: 0px 3px 6px #0000005c;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.subscribe-form_order-row-header {
  font-size: 1.5rem;
  font-weight: bolder;
  text-shadow: 0px 3px 6px #00000059;
}

.subscribe-form_order-row-header-premium {
  display: inline-block;
  color: var(--ion-color-primary);
}
.subscribe-form_order-row-header-premium-underline{
  display: inline-block;
  padding-right: 0.5rem;
  color: var(--ion-color-primary);
  text-decoration: line-through;
  text-decoration-thickness:1.5px;
  text-decoration-color: var(--ion-color-secondary);
}
.subscribe-form_order-row-text {
  padding: 0.25rem 0;
}

.subscribe-form_order__header-coupon {
  padding-top: 0.5rem;
  font-weight: 200;
}

.subscribe-form_order-fees-and-commitment-left {
  width: 50%;
}

.subscribe-form_order-fees-and-commitment-right {
  width: 50%;
}
.subscribe-form_order-fees-and-commitment-right-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  
}

.subscribe-form_order-fees-and-commitment-right-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.subscribe-form__form__checkout {
  /* width: 50%; */
  max-width: 45%;
  margin: 0 2rem;
}


@media screen and (max-width: 800px) {
  .subscribe-form_container {
    flex-direction: column;

  }
  .subscribe-form__form__checkout {
    max-width: 100%;
    margin-top: 1.5rem;
  }
  .subscribe-form_order-resume-container  {
    margin-bottom: 1.5rem;
    max-width: 100%;

  }
}

