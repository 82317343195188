.subscription-success {
    height: 100%;
    width: 100%;
    padding: 2rem;
  }

  .subscription-success__success-icon {
    padding: 1rem 0.5rem;
    height: 128px;
    width:128px;
    margin-bottom: 1rem;
  }
  .subscription-success__success-container {
    /* background-color: red; */
    color: var(--ion-color-success);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
    max-width: 650px;
    margin: auto;
  }


  .subscription-success__success-container__text {
    color: var(--ion-color-dark);
    padding: 1rem 0rem;
    font-size: 1rem;

  }

  .subscription-success__success-container__text--light {
    color: var(--ion-color-dark);
    padding: 1rem 0rem;
    font-size: 1rem;
    font-weight: 200;
  }
  .subscription-success__success-container__phone {

    font-size: 16px;
    color: var(--ion-color-dark);
  }
  .subscription-success__error-container {
    color: var(--ion-color-danger);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 32px;
    text-align: center;
    max-width: 650px;
    margin: auto;
  }
  .subscription-success__error-container ion-icon {
    font-size: 128px;
    margin-bottom: 64px;
  }
  .subscription-success__error-container__phone {
    margin-top: 16px;
    font-size: 16px;
    color: var(--ion-color-dark);
  }
  
  .subscription-success__success-container__total {
    color: var(--ion-color-secondary);
    display: flex;
    flex-direction: row;
  }

  .subscription-success__success-container__total-price {
    color: var(--ion-color-primary);
    padding-left: 0.5rem;
  }

  .subscription-success__stripe-link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    padding: 1rem 2rem;
    background-color: var(--ion-color-primary);
    border-radius: 0.35rem;
    cursor: pointer;
    box-shadow: 0px 3px 6px #0000006b;
  }

  .subscription-success__stripe-link a {
    text-decoration: none;
    color: #fff;
    font-size: 1.25rem;
  }

  .subscription-success__stripe-button {

    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--ion-color-stripe)
}

.subscription-success__video-button {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.subscription-success__video-button-logo {
  width: 35px;
  height: 35px;
  padding-left: 0.75rem;
}
.subscription-success__stripe-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin : 0.5rem 1rem 2.5rem;
}

.subscription-success__stripe-button-logo {
  width: 70px;
  height: 70px;
}
