.signup-logo {
  width: 100%;
  max-width: 24rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
}

.signup-form__header-title {
  padding: 1rem 0;
  font-size: 1.25rem;
  font-weight: bolder;
}
.signup-form__header-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  
}
.signup-role-selector {
  width: 100%;
  min-height: 117px;
  margin: 1.5rem 0;
  display: flex;
  justify-content: space-between;
}
.signup-form {
  width: 100%;
  max-width: 24rem;
}
@media screen and (min-width: 640px) {
  .signup-logo {
    padding: 1rem 0;
  }
}
