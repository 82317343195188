.call-button__button-call {

    margin: 1rem 0;
}

.call-button__call-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.call-button__call-text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 0.75rem;
}

.call-button__call-button-icon{
    height: 24px;
  width: 24px;

}

.call-button__call-text-small {
    font-size: 0.75rem;
    font-weight: 100;
    text-shadow: 0px 3px 6px #00000059;
    padding-bottom: 0.25rem;
}

.call-button__call-text-big {
    font-size: 1.25rem;
    font-weight: bolder;
    text-shadow: 0px 3px 6px #00000059;
}