.fees__main-container {
    margin: 2rem 4rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.fees__text-header {
    font-size: 1.25rem;
    text-shadow: 0px 3px 6px #7e7e7e59;
    padding : 1rem 0;
}
.fees__text {
    font-size: 1.25rem;
    text-shadow: 0px 3px 6px #7e7e7e59;
    padding : 1rem 0;
    text-align: justify;
}